import React from 'react';
import { motion } from 'framer-motion';
import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import './Header.scss';
//import CookieConsent from "react-cookie-consent";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => (
  <div className="app__header app__flex">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      {<div className="app__header-badge">
        <div className="tag-cmp app__flex">
          <p className="p-text">Custom Web Design & Wordpress</p>
          <p className="p-text">Logo Design & Branding</p>
          <p className="p-text">App & Game QA Testing</p>
          <p className="p-text">Image Restoration</p>
        </div>
</div>}
    </motion.div>

    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img"
    >
      <img src={images.bvlogo} alt="Burning Vertex Web Design & Branding Creative Studio" />
      <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        src={images.circle}
        alt="profile_circle"
        className="overlay_circle"
      />

    </motion.div>

    {<motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
    >
      {[images.adobephotoshop, images.reactskill, images.html, images.wordpress, images.vscode].map((circle, index) => (
        <div className="circle-cmp app__flex" key={`circle-${index}`}>
          <img src={circle} alt="profile_bg" />
        </div>
      ))}
    </motion.div>}
  </div>
);

export default AppWrap(Header, 'home');